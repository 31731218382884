@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 210 9% 31%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 248 77% 65%;
    --primary-foreground: 0 0% 100%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --light-gray: 210 16% 93%;
    --gray: 210 16% 93%;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    font-family: 'Montserrat', sans-serif;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 768px) {
  body {
    background: #fbfbff;
  }
}

code {
  font-family: 'Montserrat', sans-serif;
}

.password-input {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.password-input::placeholder {
  font-size: 16px;
  letter-spacing: normal;
}

/* ColorSlider */
.color-slider input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  border-radius: 3px;
}

.color-slider input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background: var(--thumb-color);
  border: 1.75px solid white;
  box-shadow: 0px 2px 8px 0px #00000040;
  cursor: pointer;
}

.color-slider input[type='range']::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background: var(--thumb-color);
  border: 1.75px solid white;
  box-shadow: 0px 2px 8px 0px #00000040;
  cursor: pointer;
}

/* SliderField */
/* For the slider track and range */
.slider {
  @apply relative flex w-full touch-none select-none items-center;
}

/* Track base (gray background) */
.radix-slider-track {
  @apply relative h-1 w-full grow overflow-hidden rounded-full bg-[#E9ECEF];
}

/* Colored part of the track (filled portion) */
.radix-slider-range {
  @apply absolute h-full bg-primary;
}

/* Hide the default thumb */
.radix-slider-thumb {
  @apply hidden;
}

/* Custom value box styles */
.value-indicator {
  @apply absolute -translate-x-1/2 -translate-y-full pointer-events-none
    border border-primary rounded-md px-2 py-1 bg-white min-w-[60px] text-center;
}

.value-text {
  @apply text-sm text-[#495057];
}

.sidebarLink-gradient {
  background: linear-gradient(90deg, #aca9ff 0%, rgba(172, 169, 255, 0) 91.25%);
}
